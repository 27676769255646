<template>
  <div class="overallpage">
    <a class="next-link close_link" v-on:click="goBack()">X</a>
    <h2>Spendenübersicht</h2>
    <p>
      Hier könnt ihr einsehen, an welche <b>Organisationen</b> wir schon gespenden haben.<br><br>
      Wenn ihr euch auch gerne neben einer Shirtspende monetär beteiligen wollt, spendet gerne einen Betrag eurer Wahl an:<br><br>
      Kontoinhaber: <b>Second Bandshirt e.V.</b><br>
      IBAN: <b>DE81 8309 4495 0003 3373 83</b><br>
      BLZ: <b>83094495</b><br><br> 
    </p>
    <div v-if="donationsExists()">
    <h3 class="donations_head">Aktuelle Spenden</h3>
    <div v-for="donation in store.donations" :key="donation.id">
      <div  v-if="donation.inUbersicht && donation.betrag > 0" class="group">
        <a v-if="donation.organisationsUrl != null" v-bind:href="donation.organisationsUrl" :target="'_blank'">{{donation.organisation}}</a>
        <p class="donations_headline" v-if="donation.organisationsUrl == null"><b>{{donation.organisation}}</b></p>
        <p v-if="donation.betrag > 0"><b>{{donation.betrag}}€</b></p>
      </div>
    </div>
    </div>

    <div>
    <h3 class="donations_head">Spendenprojekte</h3>
    <div v-for="donation in store.donations" :key="donation.id">
      <div  v-if="donation.inUbersicht && donation.betrag == null" class="group">
        <a v-if="donation.organisationsUrl != null" v-bind:href="donation.organisationsUrl" :target="'_blank'">{{donation.organisation}}</a>
        <p class="donations_headline" v-if="donation.organisationsUrl == null"><b>{{donation.organisation}}</b></p>
        <p v-if="donation.betrag > 0"><b>{{donation.betrag}}€</b></p>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Delivery',
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    donationsExists(){
      var exists = false;
      for(var i = 0; i < store.donations.length; i++){
        if(store.donations[i].betrag != null){
          exists = true;
        }
      }
      return exists;
    },
    goBack: function(){
      this.$router.back()
    }
  }
}
</script>

<style lang="scss">
  .donations_headline{
    color: $color-primary!important;
  }
  .donations_head{
    padding-left: 16px;
  }
</style>
